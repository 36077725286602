<template>
  <div>
    <TAdvancedSearch ref="TAdvancedSearch">
      <template v-slot:advanced-search>
        <Advanced
          v-if="tableSubmit"
          :searchConfiguration.sync="searchConfiguration"
          :searchObj.sync="tableSubmit"
          :tableId.sync="tableData.tabId"
        ></Advanced>
        <div></div>
      </template>
    </TAdvancedSearch>

    <KTPortlet>
      <!-- <template v-slot:toolbar> </template> -->
      <template v-slot:body>
        <b-overlay :show="loading" class="head-loading">
          <div class="operate-box">
            <Export
              v-b-popover.hover.topleft=""
              :exportText="'Export Excel'"
              :exportUrl="exportUrl"
              :exportDisabled="exportDisabled"
            ></Export>
            <CreatePurchase
              :purchaseTargetIdArr="purchaseTargetIdArr"
              :initFn="renewTable"
              :disabled="purchaseTargetIdArr.length == 0"
              class="mr-2 button"
            ></CreatePurchase>
          </div>
          <div class="operate-box-r">
            <b-form-group
              v-if="tableSubmit"
              label-cols-sm="4"
              label-cols-lg="3"
              label="View Model"
              label-align-sm="right"
              label-size="sm"
              label-for="view_mode"
            >
              <SelectLoading
                v-if="option.view_mode == 0"
                :size="0.7"
              ></SelectLoading>
              <sv-select
                v-model="tableSubmit.view_mode"
                :placeholder="$t('page.please_choose')"
                :options="option.view_mode"
                :reduce="(options) => options.value"
                @input="renewTable"
                id="view_mode"
                label="label"
              >
              </sv-select>
            </b-form-group>
          </div>
          <CustomTable
            v-if="tableSubmit"
            :itemsFn="itemsFn"
            :tableData="tableData"
            :tableObj.sync="tableSubmit"
            :tableId.sync="tableData.tabId"
            :tableLoading="loading"
            :checkboxChange="checkboxChange"
            :checkboxAll="checkboxAll"
          ></CustomTable>
        </b-overlay>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import apiBus from '@/common/apiBus/index';
import TAdvancedSearch from '@/components/TAdvancedSearch/Index';
import KTPortlet from '@/views/content/Portlet';
import Advanced from '@/components/Search/Ordinary.vue';
import CustomTable from '@/components/Table/Index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
import SelectLoading from '@/components/Loading/Index';
import Export from '@/components/Export/Index.vue';
import CreatePurchase from '@/views/content/CreatePurchase/Index.vue';
export default {
  name: 'PurchaseTarget',
  components: {
    TAdvancedSearch,
    KTPortlet,
    Advanced,
    CustomTable,
    SelectLoading,
    Export,
    CreatePurchase
  },
  data() {
    return {
      // 高级搜索配置
      searchConfiguration: {
        status: {
          type: 'checked-all',
          text: 'Status',
          allSelected: false,
          class: 'text_color_all',
          resetVal: [30, 40],
          md: 6,
          options: [
            { label: 'New', value: 30, class: 'text_yellow' },
            { label: 'Processing', value: 40, class: 'text_blue' },
            { label: 'Finished', value: 50, class: 'text_green' },
            { label: 'Voided', value: 60, class: 'text_black' },
            { label: 'On Hold', value: 70, class: 'text_on_hold' }
          ]
        },
        order_type: {
          type: 'checked-all',
          text: 'Order Type',
          allSelected: false,
          class: 'text_color_all',
          resetVal: [1, 2, 3],
          md: 6,
          options: [
            { label: 'Retail', value: 1 },
            { label: 'Wholesale', value: 2 },
            { label: 'Deposit', value: 3 },
            { label: 'Fulfillment Only', value: 4 }
          ]
        },
        // placeholder_1: {},
        // sales_channel: {
        //   type: 'select',
        //   text: 'Order Type',
        //   submitVal: null,
        //   // multiple: true,
        //   selectable: 'disabled',
        //   placeholder: this.$t('page.loading'),
        //   options: []
        // },
        // order_preferred: {
        //   type: 'checked-all',
        //   text: 'Order Preferred',
        //   allSelected: false,
        //   submitVal: [],
        //   options: [
        //     { label: 'Low cost', value: 'new' },
        //     { label: 'Fast Fulfillment', value: 'purchasing' },
        //     { label: 'N/A', value: 'finished' }
        //   ]
        // },
        cps_order_number: {
          type: 'input',
          text: 'Order ID',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        priority: {
          type: 'checked-all',
          text: 'Order Priority',
          allSelected: true,
          class: 'text_color_dashed_all',
          resetVal: [1, 2, 3],
          options: [
            { label: 'VIP', value: 1, class: 'text_red_dashed' },
            { label: 'Normal', value: 2, class: 'text_grey_dashed' },
            { label: 'Urgent', value: 3, class: 'text_yellow_dashed' }
          ]
        },
        order_date: {
          type: 'datepicker_from_to',
          md: '6',
          text: 'Order Date',
          limit: {
            // start: 30,
            end: 0
          },
          from: {
            placeholder: 'From',
            resetVal: this.format(new Date().setDate(new Date().getDate() - 30))
          },
          to: {
            placeholder: 'To',
            resetVal: this.format(new Date())
          }
        },
        brand: {
          type: 'input',
          text: 'Brand',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        brand_article_number: {
          type: 'input',
          text: 'Brand Article Number',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        purchase_id: {
          type: 'input',
          text: 'Purchase ID',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        keyword: {
          type: 'input',
          text: 'Keyword',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        view_mode: {
          type: 'select',
          text: 'View Mode',
          show: false,
          md: '0',
          placeholder: this.$t('page.please_choose'),
          options: [
            {
              value: 1,
              label: 'To be Purchased exists'
            }
          ]
        }
      },
      tableData: {
        fields: [
          {
            key: 'select',
            label: 'Select',
            variant: 'select',
            allSelect: false
          },
          {
            key: 'order_information',
            label: 'Order Information',
            sortable: true,
            variant: 'filter table-mx-180'
            // sortDesc: true
          },
          {
            key: 'order_type',
            label: 'Order Type'
          },
          {
            key: 'order_priority',
            label: 'Priority'
          },
          { key: 'image', label: 'Image', variant: 'w-110' },
          {
            key: 'article_information',
            label: 'Article Information',
            variant: 'mx-300'
          },
          {
            key: 'qty',
            label: 'Purchase Quantity'
          },
          {
            key: 'status',
            label: 'Status',
            variant: 'min-80'
          },
          {
            key: 'progress',
            label: 'Progress'
          },
          {
            key: 'action',
            label: ''
          }
        ],
        tabId: 'purchase_target',
        rowClass: '',
        class: 'new_table',
        items: [],
        status: null,
        totalItems: 9999999999999,
        pageOptions: [10, 30, 50, 100, 200]
      },
      exportUrl: '',
      exportDisabled: false,
      // 搜索提交数据
      tableSubmit: null,
      initialTableSubmit: {
        status: [30, 40],
        order_type: [1, 2, 3],
        // sales_channel: null,
        cps_order_number: null,
        priority: [1, 2, 3],
        order_date_from: this.format(
          new Date().setDate(new Date().getDate() - 30)
        ),
        order_date_to: this.format(new Date()),
        brand: null,
        brand_article_number: null,
        view_mode: null,
        purchase_id: null,
        keyword: null,
        sort: null,
        limit: 100,
        page: 1
      },
      // sort_name: '',
      option: {
        view_mode: [
          {
            value: 1,
            label: 'To be Purchased exists'
          }
        ]
      },
      loading: true,

      // 表格数据
      save_data: [],
      purchaseTargetIdArr: []
    };
  },

  methods: {
    itemsFn(ctx) {
      console.log(ctx);
      // console.log(this.tableSubmit);
      // this.tableSubmit.sort = {};
      switch (ctx.sortBy) {
        case 'order_information':
          if (ctx.sortDesc) {
            this.tableSubmit.sort = {
              order_information: 'desc'
            };
          } else {
            this.tableSubmit.sort = {
              order_information: 'asc'
            };
          }
          break;
        case '':
          this.tableSubmit.sort = null;
          break;
      }
      console.log(this.tableSubmit);
      this.$forceUpdate();
      this.CACHE.addCache(
        this.currentUser.uid,
        'PurchaseTarget',
        this.tableSubmit
      );
      this.loading = true;
      this.save_data = [];
      this.tableData.items = [];
      return apiBus.purchase
        .purchaseTargetList(this.tableSubmit)
        .then((data) => {
          // console.log(data);
          this.loading = false;
          this.tableSubmit.page = ctx.currentPage;
          this.tableSubmit.limit = ctx.perPage;
          this.tableData.items = this.searchTableData(data.data.data.list);
          this.tableData.totalItems = data.data.data.total;
          this.tableData.status = data.data.message;
          this.exportUrlFn(this.tableSubmit);
          return this.tableData.items;
        })
        .catch((error) => {
          // console.log(error);
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
          this.tableData.totalItems = 0;
          return [];
        });
    },
    outputLabel(data, val) {
      let label;
      data.forEach((item) => {
        if (item.value == val) {
          label = item.label;
        }
      });
      return label;
    },
    searchTableData(data) {
      var tableData = [];
      data.forEach((key) => {
        let {
          id,
          system_id,
          position,
          cps_order_number,
          order_date,
          order_priority,
          image_url,
          brand,
          brand_article_number,
          product_name,
          product_name_en,
          color,
          color_name,
          size,
          qty,
          status,
          not_purchased_qty,
          purchasing_qty,
          stored_qty,
          product_id,
          supplier_name,
          last_comment_level,
          pos_article_no,
          cps_order_url,
          order_type,
          comment_list
        } = key;

        tableData.push({
          id: id,
          select: false,
          system_id: system_id,
          // cps_order_number: cps_order_number,
          order_information: {
            cps_order_number: cps_order_number,
            order_date: order_date,
            cps_order_url: cps_order_url,
            position: position
          },
          order_priority: order_priority || 2,
          image: image_url,
          article_information: {
            brand: brand,
            brand_article_number: brand_article_number,
            product_name:
              product_name_en != '' && product_name_en != null
                ? product_name_en
                : product_name,
            color: color,
            color_name: color_name,
            size: size,
            pos_article_no: pos_article_no
          },
          qty: qty,
          status: status,
          progress: {
            not_purchased_qty: not_purchased_qty,
            purchasing_qty: purchasing_qty,
            stored_qty: stored_qty || 0
          },
          order_type: this.outputLabel(
            this.searchConfiguration.order_type.options,
            order_type
          ),
          supplier_name: supplier_name,
          product_id: product_id,
          grade: last_comment_level,
          comment_list: comment_list,
          action: 'PurchaseTarget'
        });
      });
      return tableData;
    },
    searchOptions() {
      if (Object.keys(this.baseConfig).length == 0) {
        apiBus.purchase
          .getBaseConfig()
          .then((data) => {
            console.log('getBaseConfig数据缓存vuex3');
            this.$store.dispatch(RENEW_BASE_CONFIG, data.data.data);
            // 给Order Type赋值
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleOptions(data) {
      var arr = [];
      data.forEach((key, i) => {
        key.index = 1;
        if (key.value) {
          arr.push(key);
        } else {
          arr.push({
            value: 'disabled_' + i,
            label: key.label,
            index: 1,
            selectable: 'disabled'
          });
          key.children.forEach((x) => {
            x.index = 2;
            arr.push(x);
          });
        }
      });
      return arr;
    },
    renewTable() {
      this.$root.$emit('bv::refresh::table', this.tableData.tabId); // 筛选强制刷新表单
    },
    ifAllCheckbox() {
      var checkboxLength = [],
        ifCheckboxL = [];
      this.tableData.items.forEach((itme) => {
        if (itme.select) {
          checkboxLength.push(itme.select);
        }
        if (itme.selectDisabled != 'disabled') {
          ifCheckboxL.push(itme.select);
        }
      });
      if (
        checkboxLength.length == ifCheckboxL.length &&
        ifCheckboxL.length > 0
      ) {
        this.tableData.fields[0].allSelect = true;
      } else {
        this.tableData.fields[0].allSelect = false;
      }
      this.processPurchaseTarget();
    },
    checkboxChange(select, index) {
      var splice_index = this.save_data.indexOf(index);
      // console.log(itme);
      // console.log(index);
      if (select) {
        this.save_data.push(index);
      } else {
        this.save_data.splice(splice_index, 1);
      }
      this.ifAllCheckbox();
    },
    checkboxAll(select) {
      if (select) {
        this.tableData.items.forEach((item) => {
          item.select = true;
        });
      } else {
        this.tableData.items.forEach((item) => {
          item.select = false;
        });
      }
    },
    processPurchaseTarget() {
      let arr = [],
        idArr = [];
      this.save_data.forEach((item) => {
        if (
          this.tableData.items[item].progress.not_purchased_qty > 0 &&
          this.tableData.items[item].status != 'Voided'
        ) {
          if (
            arr.indexOf(
              this.tableData.items[item].order_information.cps_order_number
            ) === -1
          ) {
            arr.push(
              this.tableData.items[item].order_information.cps_order_number
            );
          }
          idArr.push(this.tableData.items[item].id);
        }
      });
      if (arr.length == 1) {
        this.purchaseTargetIdArr = idArr;
      } else {
        this.purchaseTargetIdArr = [];
      }
    },
    add0(m) {
      return m < 10 ? '0' + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    },
    /**
     * param 将要转为URL参数字符串的对象
     * key URL参数字符串的前缀
     * encode true/false 是否进行URL编码,默认为true
     *
     * return URL参数字符串
     */
    urlEncode(param, key, encode) {
      if (param == null) return '';
      var paramStr = '';
      var t = typeof param;
      if (t == 'string' || t == 'number' || t == 'boolean') {
        if (key != 'limit' && key != 'page' && key != 'tab_type') {
          paramStr +=
            '&' +
            key +
            '=' +
            (encode == null || encode ? encodeURIComponent(param) : param);
        }
      } else {
        for (var i in param) {
          var k =
            key == null
              ? i
              : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
          paramStr += this.urlEncode(param[i], k, encode);
        }
      }
      return paramStr;
    },
    exportUrlFn(data) {
      console.log(data);
      this.exportUrl = `/rest/api/purchase/download-purchase-target-list?${this.urlEncode(
        data
      )}`;
    },

    init() {
      this.searchOptions();
      if (this.CACHE.ifCache('PurchaseTarget')) {
        console.log('读取缓存');
        this.tableSubmit = this.CACHE.getCache(
          this.currentUser.uid,
          'PurchaseTarget'
        );
        Object.keys(this.searchConfiguration).forEach((key) => {
          if (this.searchConfiguration[key].type == 'checked-all') {
            if (
              this.tableSubmit[key].length ==
              this.searchConfiguration[key].options.length
            ) {
              this.searchConfiguration[key].allSelected = true;
            } else {
              this.searchConfiguration[key].allSelected = false;
            }
          }
        });

        this.$refs.TAdvancedSearch.visible =
          this.CACHE.getVisible('PurchaseTarget');
      } else {
        console.log('没有读取缓存');
        this.tableSubmit = this.initialTableSubmit;
      }
    }
  },

  computed: {
    ...mapState({
      baseConfig: (state) => state.baseconfig.baseconfig
    }),
    ...mapGetters(['currentUser']) // 获取用户名
  },
  mounted() {
    this.init();
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId == 'advanced-search') {
        this.CACHE.addVisible('PurchaseTarget', isJustShown);
      }
    });
  }
};
</script>
<style scoped>
.filter {
  display: flex;
  align-content: center;
  justify-content: center;
}
.filter .form-group {
  margin-bottom: 0;
  width: 200px;
}
#view_mode {
  width: 220px;
}
</style>
